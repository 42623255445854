<template>
  <div>
    <div class="field-label" :class="required ? 'required' : ''">
      <span>{{ name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "FieldLabel",
  components: {},
  props: {
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.field-label {
  font-size: 16px;
  display: block;
  color: #646566;
  padding: 10px 0 0 10px;
  background: #fff;
  span {
    padding-left: 5px;
  }
}
.required {
  span {
    position: relative;
    &:before {
      content: "*";
      position: absolute;
      top: 2px;
      left: -5px;
      color: red;
    }
  }
}
</style>