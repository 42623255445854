import request from '@/utils/request'

// 获取工单列表
export function getGongdanList(data) {
  return request({
    method: 'post',
    path: 'webapi/waterOrder/orderList',
    dataType: 'json',
    data,
  })
}

// 获取工单详情
export function getGongdanDetail(data) {
  return request({
    method: 'get',
    path: 'webapi/waterOrder/getOrder',
    data
  })
}


// 获取工单详情
export function getGongdanRecordList(data) {
  return request({
    method: 'get',
    path: 'webapi/waterOrder/getOrderRecord',
    data
  })
}

// 保存工单
export function addGongdan(data) {
  return request({
    method: 'post',
    path: 'webapi/waterOrder/addGongdan',
    dataType: 'json',
    data
  })
}

// 保存工单记录
export function saveGongdanRecord(data) {
  return request({
    method: 'post',
    path: 'webapi/waterOrder/saveOrderRecord',
    dataType: 'json',
    data
  })
}
// 审核接口
export function postShenHe(data) {
  return request({
    method: 'post',
    path: 'webapi/waterOrder/saveWaterDispose',
    dataType: 'json',
    data
  })
}
//获取站点详情的
export function siteDetail(data) {
  return request({
    method: 'get',
    path: 'webapi/waterSite/getSite',
    data
  })
}